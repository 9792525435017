import { render, staticRenderFns } from "./new-video.vue?vue&type=template&id=16520c3b&scoped=true"
import script from "./new-video.vue?vue&type=script&lang=js"
export * from "./new-video.vue?vue&type=script&lang=js"
import style0 from "./new-video.vue?vue&type=style&index=0&id=16520c3b&prod&scoped=true&lang=css"
import style1 from "./new-video.vue?vue&type=style&index=1&id=16520c3b&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16520c3b",
  null
  
)

export default component.exports