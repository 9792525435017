<script>
  
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import MultiRangeSlider from "multi-range-slider-vue";
import _debounce from 'lodash.debounce';
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";

import Plyr from 'plyr'
import 'vue-plyr/dist/vue-plyr.css'


import {
	videoMethods,
  projectMethods
} from "@/state/helpers";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "New Video",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    MultiRangeSlider,
    Multiselect,
    
  },
  data() {
    return {
      title: "Video",
      items: [],
      showModalHost: false,
      showModalProducts:false,
      isLoading: false,
      fullPage: true,
      canCancel: false,
      useSlot: false,
      color: "#007bff",
      bgColor: "#ffffff",
      lineHeight: 3,
      itemsAll:[],
      timer: 0,
      duration:2000000,
      interval:null,
      selectedProduct:null,
      selectedProducts:[],
      products:[],
      campaigns:[],
      loadingProducts:false,
      isProductsVisible : true,
      isPlaying: false,
      isVideoVisible:false,
      video:{
        name: null,
        campaign:null,
        resolution:'16_9',
        type: 'youtube',
        video: null,
        status: 'draft',
        project: localStorage.getItem('current_project')
      },
      submitted:false,
      link :null,
      type:'vimeo',
      plyr : null,
      player_html:'',
      published: this.video && this.video.status == 'published',
      modalVideoShow:false
    }
  },
  mounted() {
    const videoId = this.$route.query.id;
    if(videoId){
      this.title = this.$t('videos.update_video_title');
      let loader = this.$loading.show();
      this.getVideo(videoId).then((res)=>{
        this.video = res.data;
        this.type= this.video.type;
        this.published = this.video.status && this.video.status == 'published'
        this.onVideoLoadOrRemoveClicked();
      }).catch(()=>{

      }).finally(()=>{
        loader.hide();
      })
    }else{
      this.title = this.$t('videos.update_video_title');
    }
  },
  created() {
    this.debounceProducts = _debounce(this.searchProduct.bind(this), 1000);
  },
  validations: {
    video: {
      name: { required },
      video:{required}
    },
  },
  methods: {
    ...videoMethods,
    ...projectMethods,

    onTestVideoClicked(){
      this.modalVideoShow = true;
    },

    mountInstance () {
      var iDiv = document.createElement('div');
      iDiv.id='playerId';
      iDiv.setAttribute('data-plyr-embed-id', this.video.video);
      iDiv.setAttribute('data-plyr-provider', this.video.type);
      
      var eElement = document.getElementsByClassName('video_container')[0];
      eElement.insertBefore(iDiv, eElement.firstChild);

      this.plyr = new Plyr(document.getElementById('playerId'), {
        controls: ['play', 'progress', 'pip', 'airplay'],
        hideControls: true
      })
      
      this.plyr.on('ready', (event) => {
        if(this.video.type!='vimeo'){
          this.duration = event.detail.plyr.duration;
        }
        

        if(this.video.type=='vimeo')
          this.playVideo();

        if(this.video && this.video.config)
          this.itemsAll = this.video.config;
      })
      
      this.plyr.on('timeupdate', (event) => {
        
        this.videoTimeUpdated(event);  
        if(this.duration!=Math.trunc(event.detail.plyr.duration) && this.video.type== 'vimeo'){
          this.duration = Math.trunc(event.detail.plyr.duration);
        }
      });

      // eslint-disable-next-line no-unused-vars
      this.plyr.on('play', (event) => {
        this.isPlaying = true;
        this.playVideo();
      });

      // eslint-disable-next-line no-unused-vars
      this.plyr.on('pause', (event) => {
        this.isPlaying = false;

        this.pauseVideo();
      });
    },

    onVideoLoadOrRemoveClicked(){
      if(!this.isVideoVisible){
        
        if(this.video.video){
          this.isVideoVisible = true;
          this.mountInstance();
          //this.link = this.video.video;
          
        }
      }else{
        this.plyr.destroy();
        this.player_html='';
        this.video.video = null;
        this.isVideoVisible = false;
      } 
    },
    onCancelCreateOrEditVideoClicked(){
      this.$router.push(
              {
                path: "/videos",
              }
            ).catch(() => {});
    },
    onConfirmEditVideoClicked(){
      let loader = this.$loading.show();
      this.video.config = this.itemsAll;
      this.video.status = this.published ? 'published' : 'draft';
      this.updateVideo(this.video).then(()=>{
        this.$notify({ type: 'success', text: this.$t("videos.update_videos_success"),title:  this.$t("videos.title") });
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t("videos.update_videos_error"),title:  this.$t("videos.title") });
      }).finally(()=>{
        this.isLoading = false;
        loader.hide();
      });
    },

    onConfirmCreateVideoClicked(){

      let loader = this.$loading.show();
      this.video.config = this.itemsAll;
      this.video.status = this.published ? 'published' : 'draft';

      this.createVideo(this.video).then(()=>{
        this.$notify({ type: 'success', text: this.$t("videos.new_videos_success"),title:  this.$t("videos.title") });
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t("videos.new_videos_error"),title:  this.$t("videos.title") });
      }).finally(()=>{
        this.isLoading = false;
        loader.hide();
      });
    },

    onConfirmCreateOrEditVideoClicked(){
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      const videoId = this.$route.query.id;

      if(videoId){
        this.onConfirmEditVideoClicked()
      }
      else{
        this.onConfirmCreateVideoClicked();
      }
      
    },

    onSelectProductsClicked(){
      
      if(this.itemsAll.filter(p=> p.data.sku== this.selectedProduct.data.sku).length==0){
        this.itemsAll.push({
        data: this.selectedProduct.data,
        start : Math.trunc(this.plyr.currentTime),
        end: this.duration
,      })
      }
      this.selectedProduct = null;
    },

    nameWithSKU(p) {
      return `${p.data.sku} — [${p.data.name}]`
    },
    
    searchProduct(query){
      
      this.isLoading = true;

      if(query){
        this.products=[];
        const params ={
					q: `whereObj={"project":"${localStorage.getItem('current_project')}", "data.active" : 1, "$or": [{"data.name" : {"$regex" : ".*${query}*." , "$options": "i"} }, {"data.sku" : {"$regex" : ".*${query}*." , "$options": "i"}} ]}`
				}

        this.getItems(params).then((products)=>{
          if(products&& products.data){
            this.products= products.data;
          }
          this.isLoading = false;
        })
      }else{
        this.products = [];
        this.isLoading = false;
        this.selectedProduct=null;
      }
    },
    resetVideo(){
      this.timer = 0;
      this.plyr.currentTime = 0;
      this.playVideo();
     }, 
    playVideo(){
      this.isPlaying = true;
      this.interval = setInterval(()=>{
        /*if(this.timer >=this.duration){
          clearInterval(this.interval);
          this.isPlaying = false;
        }*/
      },1000);
      this.plyr.play();
     }, 
    stopVideo(){
      this.timer =0;
      
      this.isPlaying = false;
      
      if(this.interval){
        clearInterval(this.interval);
      }
      this.plyr.stop();
    }, 

    pauseVideo(){
      this.isPlaying = false;
      if(this.interval){
        clearInterval(this.interval);
      }
      this.plyr.pause();
    },

    UpdateValues(sku) {

      let item = this.itemsAll.filter(i=>i.data.sku==sku)[0];

      item.start = this.$refs['range_' + sku][0].valueMin;
      item.end = this.$refs['range_' + sku][0].valueMax;

      this.$refs[sku][0].style['margin-left'] = document.getElementsByClassName(sku)[0].getElementsByClassName("thumb-left")[0].offsetLeft + "px";
    },

    uploadFile(){
      //TODO
    },

    removeProduct(item){
      this.itemsAll = this.itemsAll.filter(i=> i.data.sku!= item.data.sku);
     },

    videoTimeUpdated() {

    this.timer = Math.trunc(this.plyr.currentTime);

      if(this.timer >=this.duration){
        clearInterval(this.interval);
      }
    }

  }
};

</script>
  



<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div>
      <div class="row">
        <div class="col-sm-6">
          <div class="row">
            <div class=" col mb-3">
              <label for="videoName" class="control-label">{{$t('videos.name')}}</label>
                <input
                  id="videoName"
                  v-model="video.name"
                  name="name"
                  type="text"
                  class="form-control"
                  :placeholder="$t('videos.name_placeholder')"
                  :class="{
                    'is-invalid': submitted && $v.video.name.$error,
                  }"
                />
                <div v-if="submitted && !$v.video.campaign.required" class="invalid-feedback">
                {{$t('videos.name_required')}}
              </div>
            </div>
          </div> 
          <div class="mb-3">
            <label for="campaignImage">Video</label>
              <div class="input-group">
                <b-dropdown split :text="video.type" class="m-2" variant="primary">
                  <b-dropdown-item @click="video.type = 'youtube'">Youtube</b-dropdown-item>
                  <b-dropdown-item @click="video.type = 'vimeo'">Vimeo</b-dropdown-item>
                  <b-dropdown-item @click="video.type = 'video'">Nuevo (soon)</b-dropdown-item>
                </b-dropdown>
                <input
                  type="text"
                  class="form-control m-2"
                  :placeholder="$t('videos.video_placeholder')"
                  v-model="video.video"
                  v-if="video.type!='video'"
                  :disabled="isVideoVisible"
                  :class="{'is-invalid': submitted && $v.video.video.$error,}"
                />
                <b-button v-if="video.type!='video'" class="btn m-2" :variant="isVideoVisible? 'danger' : 'primary'" @click="onVideoLoadOrRemoveClicked"> {{isVideoVisible? $t('videos.video_remove') : $t('videos.video_load')}} </b-button>
                <input
                  ref="campaignVideo"
                  accept="video/*"
                  class="form-control m-2"
                  id="campaignImage"
                  type="file"
                  @input="uploadFile"
                  placeholder="Pick the video file"
                  v-if="video.type=='video'"
                  />
                <div v-if="submitted && !$v.video.video.required" class="invalid-feedback">
                {{$t('videos.video_required')}}
              </div>
              </div>
          </div>

          <div class="mb-3">
            <label for="campaignImage">{{$t('videos.add_products')}}</label>
            <div>
              <multiselect 
                        v-model="selectedProduct" 
                        id="products" 
                        label="name" 
                        track-by="_id" 
                        :selectLabel="$t('common.select')"
                        :deselectLabel="$t('common.deselect')"
                        :placeholder="$t('campaigns.new_campaign_products_select')"
                        open-direction="bottom" 
                        :options="products" 
                        :multiple="false" 
                        :searchable="true" 
                        :internal-search="false" 
                        :close-on-select="true" 
                        :options-limit="300" 
                        :limit="1" 
                        :max-height="600" 
                        :show-no-results="false" 
                        :hide-selected="false"
                        @search-change="debounceProducts"
                        @input="onSelectProductsClicked"
                        :custom-label="nameWithSKU">
                        <template v-slot:noOptions>
                          {{$t('campaigns.new_campaign_products_empty')}}
                        </template>
                        
                      </multiselect>
                <div class="col-sm-6 text-sm-end">
                  <b-spinner
                    v-if="isLoading"
                    class="m-2"
                    variant="primary"
                    role="status">
                  </b-spinner>
                </div>
            </div>
          </div>
        </div>
        <div class="col" style="margin: auto;">
           <div class="video_container" style="width: 400px; height: 300px; margin: auto;">
              <div class="d-flex" style="width:100%; margin:auto;" v-if="isVideoVisible">
                <b-button v-if="!isPlaying" variant="outline-primary" @click="playVideo()" style="margin:5px; border:none; font-size:20px"><i class="bx bx-play-circle"/></b-button>
                <b-button v-if="isPlaying" variant="outline-primary" @click="pauseVideo()" style="margin:5px; border:none; font-size:20px"><i class="bx bx-pause-circle"/></b-button>
                <b-button variant="outline-primary" @click="stopVideo()" style="margin:5px; border:none; font-size:20px"><i class="bx bx-stop-circle"/></b-button>
                <b-button variant="outline-primary" @click="resetVideo()" style="margin:5px; border:none; font-size:20px"><i class="bx bx-reset"/></b-button>
              </div>
            </div>
        </div>
      </div>
      
    <div v-if="isProductsVisible" style="padding-top:50px; min-height:250px">
      <div class="row" v-for="item in itemsAll" v-bind:key="item.data.sku">
        <div class="col">
          <div style="height:50px; padding-bottom:5px;">
            
            <b-progress width="100%" height="50px" :value="timer" :max="duration" variant='info' style="background-color: rgba(80,165,241,.25)!important;">
            </b-progress>
            
            <div class="progress-item">
                <MultiRangeSlider
                    :min="0"
                    :max="duration"
                    :step="1"
                    :ruler="false"
                    :label="true"
                    :minValue="item.start"
                    :maxValue="item.end"
                    @input="UpdateValues(item.data.sku)"
                    :class="item.data.sku"
                    :ref="`range_${item.data.sku}`"
                  />
                  <u :ref="item.data.sku" class="ul-card">{{`${item.data.sku}-${item.data.name}`}} <i v-on:click="removeProduct(item)" class="bx bx-trash"/></u>
            </div>
          </div>
          
        </div>
        
      <div>
    </div>
      
    </div>
    </div>
    </div>
    <div class="row mt-4">
      <div class="col-sm-6">
      </div>
      <!-- end col -->
      <div class="col-sm-6">
        <div class="text-end">
          <b-button variant="light" class="w-md mb-3" @click="onCancelCreateOrEditVideoClicked">{{$t('common.cancel')}}</b-button>
          <b-button variant="primary" class="w-md ms-3 mb-3" @click="onConfirmCreateOrEditVideoClicked">{{$t('common.confirm')}}</b-button>
        </div>
      </div>
      <!-- end col -->
    </div>
  </Layout>
</template>

<style scoped>

.progress-item {
    left: 10px;
    top: -33px;
    position: relative;
    height: 50px;
    z-index: 1000;
}

.multi-range-slider {
  box-shadow: unset !important;
  border: unset !important;
  top: -20px !important;
  left:-20px !important;
}

.ul-card {
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
  text-decoration: none;
  background-color: white;
  padding: 0px 10px;
  margin:10px;
  border-radius: 8px; 
  position:absolute;
  top:0px;
}

.video_container .video-js {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 110;
  background-color: transparent;
}

.published_switch {
  position: absolute;
  top: -50px;
  right: 10px;
}

.test_button {
  position: absolute;
  top: -60px;
  right: 130px;
  font-size: 15px;
  text-decoration: underline;
}

.test_modal_content{
  height: 400px;
  width: 400px;
}

</style>

<style>

.vjs-big-play-button{
  visibility: hidden;
}

.multi-range-slider .bar-inner {
  background-color: #5e40bf;
}

.multiselect__content-wrapper{
  z-index: 2000;
}

.multiselect--active {
  z-index: 2000;
}
</style>